import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { DropdownList, InputField } from "../../Components";
import { genderTypes, kidGenderTypes } from "../../Global";
import { useDispatch, useSelector } from "react-redux";
import { isValidEmail, routeName, showToast } from "../../Utility";
import { useNavigate } from "react-router-dom";
import {
  loggedInUsedAction,
  registerFieldsAction,
} from "../../Redux/Actions/AuthActions";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import PhoneInput from "react-phone-input-2";
import { VerifyOtp } from "../../Components/Popups";
import { getUserDetail, verifyOtpApi } from "../../Redux/Services/AuthServices";

const BasicDetails = ({
  talent,
  setTalent,
  validated,
  steps,
  editDetail,
  setEditDetail,
  setEditSecStep,
  basicDetails,
  setBasicDetails,
  email,
  setEmail,
  gender,
  setGender,
  password,
  setPassword,
  agree,
  setAgree,
  legal,
  setLegal,
  relationStatus,
  setRelationStatus,
  phoneCode,
  setPhoneCode,
  isVerifyModal,
  setIsVerifyModal,
  otp,
  setOtp,
  countDown,
  setCountDown,
  handlePhoneVerify,
  handleForm,
  error,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailValid = isValidEmail(email);
  const [countryCode, setCountryCode] = useState(phoneCode);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const optionsType = useSelector((state) => state.authReducer.allOptionData);
  const registerFields = useSelector(
    (state) => state.authReducer.registerFields
  );

  const handleTermsConditions = async (e) => {
    e.stopPropagation();
    let body = {
      firstName: basicDetails?.fname,
      lastName: basicDetails?.lname,
      gender: gender,
      displayName: basicDetails?.displayName,
      phone: basicDetails?.mobileNumber,
      phoneCode: phoneCode,
      guardianName: basicDetails?.guardianName,
      password: password,
      dob: basicDetails?.birthDate,
      relationStatus: relationStatus,
      tagLine: basicDetails?.tagLine,
    };
    dispatch(registerFieldsAction(body));
    navigate(routeName.TERMS_CONTIONS);
  };
  useEffect(() => {
    if (registerFields) {
      setBasicDetails({
        ...basicDetails,
        fname: registerFields?.firstName || "",
        lname: registerFields?.lastName || "",
        displayName: registerFields?.displayName || "",
        mobileNumber: registerFields?.phone || "",
        birthDate: registerFields?.dob || "",
        guardianName: registerFields?.guardianName,
        tagLine: registerFields?.tagLine || "",
      });
      setPhoneCode(registerFields?.phoneCode);
      setPassword(registerFields?.password);
      setGender(registerFields?.gender);
      setRelationStatus(registerFields?.relationStatus || "");
    }
  }, [registerFields]);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: userID ? JSON?.parse(userID) : "",
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status == 200) {
        storeData(
          storageKey.USER_DATA,
          JSON.stringify(res?.results?.user_data)
        );
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
      }
    }
  };

  const handleChangePhone = (phone, country) => {
    setPhoneCode(country?.dialCode);
    setCountryCode(country?.dialCode);
    setBasicDetails({
      ...basicDetails,
      mobileNumber: phone?.replace(country.dialCode, ""),
    });
    // setCountryName(country?.countryCode);
  };
  return (
    <div>
      {steps == 2 || steps == 3 ? (
        <div
          className="form-section-title"
          style={{ backgroundColor: editDetail ? "#fff" : "#ffffff57" }}
          onClick={() => {
            setEditDetail(!editDetail);
            setEditSecStep(false);
          }}
        >
          <span>1. Your basics</span>
          <p>Edit</p>
        </div>
      ) : null}
      {steps == 1 || editDetail || steps == 0 ? (
        <div>
          <Form noValidate validated={validated} onSubmit={handleForm}>
            <div class="reg_application">
              <div class="form-section">
                <div className="form-section-title">Your basics</div>

                <div class="form-group form-group-50">
                  {talent != "Client" && talent != "Partners" ? (
                    <>
                      <InputField
                        required
                        title="Talent Type"
                        placeholder="Select User Type"
                        type="text"
                        value={talent}
                        onChangeText={(e) => {
                          setTalent(e?.target?.value);
                        }}
                        disabled={true}
                        isValid={error && !talent}
                      />
                      <InputField
                        required
                        title="Date of birth"
                        type="date"
                        value={basicDetails?.birthDate}
                        placeholder="DD/MM/YYYY"
                        onChangeText={(e) => {
                          setBasicDetails({
                            ...basicDetails,
                            birthDate: e?.target?.value,
                          });
                        }}
                        talent={talent}
                        disabled={steps == 2 || steps == 3 ? true : false}
                        isValid={error && !basicDetails?.birthDate}
                      />

                      <DropdownList
                        required
                        title="Gender"
                        placeholder="Select Gender"
                        options={
                          talent == "Model Kid" ||
                          talent == "Actor Kid" ||
                          talent == "child"
                            ? kidGenderTypes
                            : genderTypes
                        }
                        value={gender}
                        setValue={setGender}
                        disabled={steps == 2 || steps == 3 ? true : false}
                        isValid={error && !gender}
                      />
                    </>
                  ) : undefined}

                  <InputField
                    required
                    title="First name"
                    type="text"
                    placeholder="First name"
                    value={basicDetails?.fname}
                    onChangeText={(e) => {
                      setBasicDetails({
                        ...basicDetails,
                        fname: e?.target?.value,
                      });
                    }}
                    isValid={error && !basicDetails?.fname}
                  />
                  <InputField
                    required
                    title="Last name"
                    type="text"
                    placeholder="Last name"
                    value={basicDetails?.lname}
                    onChangeText={(e) => {
                      setBasicDetails({
                        ...basicDetails,
                        lname: e?.target?.value,
                      });
                    }}
                    isValid={error && !basicDetails?.lname}
                  />
                  <InputField
                    required
                    title="Display name"
                    type="text"
                    placeholder="Display name"
                    value={basicDetails?.displayName}
                    onChangeText={(e) => {
                      setBasicDetails({
                        ...basicDetails,
                        displayName: e?.target?.value,
                      });
                    }}
                    isValid={error && !basicDetails?.displayName}
                  />

                  {talent == "Model Kid" || talent == "Actor Kid" ? (
                    <>
                      <InputField
                        required
                        title="Guardian Name"
                        type="text"
                        placeholder="Guardian Name"
                        value={basicDetails?.guardianName}
                        onChangeText={(e) => {
                          setBasicDetails({
                            ...basicDetails,
                            guardianName: e?.target?.value,
                          });
                        }}
                        isValid={error && !basicDetails?.guardianName}
                      />
                      <div className="phone_inpt">
                        <Form.Label>
                          <span className="astrik_req">*</span> Phone Number
                        </Form.Label>
                        <div className="numberForm">
                          <PhoneInput
                            value={phoneCode + basicDetails?.mobileNumber}
                            enableSearch={true}
                            country="us"
                            placeholder="Phone number"
                            containerClass={"cntn_telCls"}
                            inputClass={"cstm_cls"}
                            buttonClass={"custm_btn"}
                            dropdownClass={"custom-dropdown"}
                            onChange={(phone, country) =>
                              handleChangePhone(phone, country)
                            }
                          />

                          {loggedInUser?.user_data?.phone_verified_status &&
                          basicDetails?.mobileNumber ==
                            loggedInUser?.fw_option[0]?.user_phone_number &&
                          loggedInUser?.user_data?.country_code == phoneCode ? (
                            <div>
                              <i class="fa fa-check"></i> Verified
                            </div>
                          ) : (!loggedInUser?.user_data
                              ?.phone_verified_status ||
                              basicDetails?.mobileNumber !=
                                loggedInUser?.fw_option[0]?.user_phone_number ||
                              loggedInUser?.user_data?.country_code !==
                                phoneCode) &&
                            loggedInUser?.user_data?.user_id ? (
                            <span onClick={() => setIsVerifyModal(true)}>
                              Verify
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {/* <InputField
                        required
                        title="Guardian phone number"
                        type="number"
                        placeholder="Guardian phone number"
                        value={basicDetails?.mobileNumber}
                        onChangeText={(e) => {
                          setBasicDetails({
                            ...basicDetails,
                            mobileNumber: e?.target?.value,
                          });
                        }}
                        isValid={error && !basicDetails?.mobileNumber}
                      /> */}
                    </>
                  ) : (
                    <div className="phone_inpt">
                      <Form.Label>
                        <span className="astrik_req">*</span> Phone Number
                      </Form.Label>
                      <div className="numberForm">
                        <PhoneInput
                          value={phoneCode + basicDetails?.mobileNumber}
                          enableSearch={true}
                          country="us"
                          placeholder="Phone number"
                          containerClass={"cntn_telCls"}
                          inputClass={"cstm_cls"}
                          buttonClass={"custm_btn"}
                          dropdownClass={"custom-dropdown"}
                          onChange={(phone, country) =>
                            handleChangePhone(phone, country)
                          }
                        />
                        {loggedInUser?.user_data?.phone_verified_status &&
                        basicDetails?.mobileNumber ==
                          loggedInUser?.fw_option[0]?.user_phone_number &&
                        loggedInUser?.user_data?.country_code == phoneCode ? (
                          <div>
                            <i class="fa fa-check"></i> Verified
                          </div>
                        ) : (!loggedInUser?.user_data?.phone_verified_status ||
                            basicDetails?.mobileNumber !=
                              loggedInUser?.fw_option[0]?.user_phone_number ||
                            loggedInUser?.user_data?.country_code !==
                              phoneCode) &&
                          loggedInUser?.user_data?.user_id ? (
                          <span onClick={() => setIsVerifyModal(true)}>
                            Verify
                          </span>
                        ) : null}
                      </div>
                    </div>
                    // <InputField
                    //   required
                    //   title="Phone number"
                    //   type="number"
                    //   placeholder="Phone number"
                    //   value={basicDetails?.mobileNumber}
                    //   onChangeText={(e) => {
                    //     setBasicDetails({
                    //       ...basicDetails,
                    //       mobileNumber: e?.target?.value,
                    //     });
                    //   }}
                    //   isValid={error && !basicDetails?.mobileNumber}
                    // />
                  )}

                  {talent === "Actor" ? (
                    <DropdownList
                      title="Relationship Status"
                      placeholder="Select Status"
                      options={
                        optionsType?.actorRelation?.relationship_status
                          ?.field_meta?.choices
                      }
                      value={relationStatus}
                      setValue={setRelationStatus}
                    />
                  ) : undefined}

                  <InputField
                    required
                    title="Email"
                    type="Email"
                    placeholder="Email"
                    value={email}
                    onChangeText={(e) => {
                      setEmail(e?.target?.value);
                    }}
                    // isValid={emailValid}
                    // disabled
                    isValid={error && !email}
                    // disabled={steps == 2 || steps==3 ? true : false}
                  />
                  <InputField
                    required
                    title="Password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChangeText={(e) => {
                      setPassword(e?.target?.value);
                      storeData(storageKey.SEND_PASS, e?.target?.value);
                    }}
                    isValid={error && !password}
                    disabled={steps == 2 || steps == 3 ? true : false}
                  />

                  {talent == "Client" ||
                  talent == "Partners" ||
                  talent == "Photographer" ? (
                    <InputField
                      title="Tagline"
                      type="text"
                      placeholder="Tagline"
                      value={basicDetails?.tagLine}
                      onChangeText={(e) => {
                        setBasicDetails({
                          ...basicDetails,
                          tagLine: e?.target?.value,
                        });
                      }}
                      toolTipText={
                        "This is a quick line to help promote you. It will be shown on your profile page. Ex. Outgoing and Friendly"
                      }
                    />
                  ) : null}

                  {talent == "Model Kid" || talent == "Actor Kid" ? (
                    <div className="fullCol">
                      <span>
                        You need to provide the gardian information for kids
                        registeration eg. Email, Phone
                      </span>
                      <Form.Group
                        controlId="formBasicCheckbox"
                        onClick={() => setLegal(!legal)}
                      >
                        <Form.Check
                          type={"checkbox"}
                          label="I am the legal guardian"
                          defaultChecked={legal}
                          required
                          disabled={steps == 2 || steps == 3 ? true : false}
                        />
                      </Form.Group>
                    </div>
                  ) : null}

                  <Form.Group
                    controlId="formBasicCheckbox"
                    onClick={() => setAgree(true)}
                    className="fullCol"
                  >
                    <Form.Check
                      type={"checkbox"}
                      onClick={() => setAgree(true)}
                      label={
                        <>
                          {" "}
                          Agree to our{" "}
                          <span
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={(e) => {
                              handleTermsConditions(e);
                            }}
                          >
                            Terms and Conditions
                          </span>
                        </>
                      }
                      required
                      disabled={steps == 2 || steps == 3 ? true : false}
                    />
                  </Form.Group>
                </div>
              </div>
              {editDetail ? null : steps != 0 ? (
                <Button variant=" wt-btn" class="wt-btn hide_org" type="submit">
                  Save
                </Button>
              ) : null}
            </div>
          </Form>
        </div>
      ) : null}
      {isVerifyModal && (
        <VerifyOtp
          basicDetails={basicDetails}
          phoneNumber={basicDetails?.mobileNumber}
          setBasicDetails={setBasicDetails}
          phoneCode={phoneCode}
          setPhoneCode={setPhoneCode}
          otp={otp}
          setOtp={setOtp}
          countDown={countDown}
          setCountDown={setCountDown}
          handleVerify={handlePhoneVerify}
          visible={isVerifyModal}
          setVisible={setIsVerifyModal}
          getUserData={getUserData}
        />
      )}
    </div>
  );
};

export default BasicDetails;
