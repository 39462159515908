import React, { useEffect, useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, useNavigate } from "react-router-dom";
import {
  clearData,
  getData,
  removeData,
  storageKey,
  storeData,
} from "../../Utility/Storage";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../Redux/Services/AuthServices";
import { routeName, showToast } from "../../Utility";
import { Button, Modal } from "react-bootstrap";
import { DeletePost } from "../../Components";
import { partnerAccessPermissions } from "../../Redux/Actions/PartnerActions";
import { loggedInUsedAction } from "../../Redux/Actions/AuthActions";
import { selectedTabAction } from "../../Redux/Actions/AdminActions";
const Sidebar = ({ setIsVisible, isVisible }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);

  const [accountApproval, setAccountApproval] = useState(true);
  const [show, setShow] = useState(false);
  const [expandedKey, setExpandedKey] = useState(null);
  const [permissions, setPermissions] = useState("");
  const [isAccess, setIsAccess] = useState(false);

  // console.log(show, isVisible, "llllll");

  useEffect(() => {
    if (partnerPermissions?.email) {
      setPermissions(partnerPermissions);
    }
  }, [partnerPermissions]);

  const toggleSubItems = (key) => {
    if (expandedKey === key) {
      setExpandedKey(null);
    } else {
      setExpandedKey(key);
    }
  };

  useEffect(() => {
    getApprovalStatus();
    // getUserData();
  }, []);

  const getApprovalStatus = async () => {
    let accountApproval = await getData(storageKey?.APPROVAL_STATUS);
    setAccountApproval(JSON?.parse(accountApproval));
  };

  const getUserData = async () => {
    if (loggedInUser?.user_data?.user_id) {
      let body = {
        user_id: loggedInUser?.user_data?.user_id || "",
      };
      await dispatch(getUserDetail(body));
    }
  };

  const logout = async () => {
    clearData();
    dispatch(loggedInUsedAction(null));
    dispatch(partnerAccessPermissions(null));
    showToast("Logged out successfully!", "success");
    setShow(false);
    getUserData();
    navigate(routeName?.HOME);
  };

  const dropItems = [
    {
      key: 1,
      name: "Dashboard",
      path: routeName?.DASHBOARD,
      icon: "th-large",
      hide: false,
    },
    {
      key: 2,
      name: "Inbox",
      path: routeName?.CHAT,
      icon: "envelope",
      hide: loggedInUser?.user_data?.profile_approval ? false : true
    },
    {
      key: 3,
      name: "Settings",
      path: "",
      icon: "gear",
      hide: loggedInUser?.user_data?.profile_approval ? false : true,
      subItem: [
        {
          key: 4,
          name: accountApproval ? "Edit Profile" : "Edit Application",
          path: routeName?.EDIT_PROFILE,
          icon: "pencil-square",
          hide: false,
          isClientVisble: true,
        },
        {
          key: 5,
          name: "Identity Verification",
          path: routeName?.IDENTITY_VERIFY,
          icon: "check-square",
          hide: false,
          isClientVisble: true,
        },
        {
          key: 6,
          name: "Payout Settings",
          path: routeName?.PAYOUT,
          icon: "money",
          hide:
            (Number(loggedInUser?.user_data?.user_role) == 12 ||
              Number(loggedInUser?.user_data?.user_role) === 14) 
              ? true
              : false,
          isClientVisble: false,
        },
        {
          key: 7,
          name: "Transaction History",
          path: routeName?.ALL_TRANSACTIONS,
          icon: "camera",
          hide:
            (Number(loggedInUser?.user_data?.user_role) == 12 ||
              Number(loggedInUser?.user_data?.user_role) === 14) 
              ? false
              : true,
          isClientVisble: true,
        },
        {
          key: 7,
          name: "Manage Availability",
          path: routeName?.MANAGE_AVAILABILITY,
          icon: "calendar",
          hide:
            (Number(loggedInUser?.user_data?.user_role) == 12 ||
              Number(loggedInUser?.user_data?.user_role) === 14) 
              ? true
              : false,
          isClientVisble: false,
        },
        {
          key: 8,
          name: "Account Settings",
          path: routeName?.ACCOUNT_SETTING,
          icon: "gear",
          hide: Number(loggedInUser?.user_data?.profile_approval)
            ? false
            : true,
          isClientVisble: true,
        },
      ],
    },

    {
      key: 9,
      name: "Post Job",
      path: routeName?.JOB_POST,
      icon: "plus-square",
      hide:
        (Number(loggedInUser?.user_data?.user_role) == 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14) &&
        loggedInUser?.user_data?.profile_approval
          ? // &&
            // loggedInUser?.user_data?.identity_status !== 0
            false
          : true,
    },
    {
      key: 10,
      name: "Post Calling Calls",
      path: routeName?.CASTING_JOB,
      icon: "plus-square",
      hide:
        (Number(loggedInUser?.user_data?.user_role) == 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14) &&
        loggedInUser?.user_data?.profile_approval
          ? // &&
            // loggedInUser?.user_data?.identity_status !== 0
            false
          : true,
    },
    {
      key: 11,
      name: "Manage Portfolio",
      path: "",
      icon: "camera",
      hide:
        Number(loggedInUser?.user_data?.user_role) !== 12 &&
        Number(loggedInUser?.user_data?.user_role) !== 14 &&
        loggedInUser?.user_data?.profile_approval
          ? //  &&
            // loggedInUser?.user_data?.identity_status !== 0
            false
          : true,
      subItem: [
        {
          key: 12,
          name: "Add Portfolio",
          path: routeName?.ADD_PORTFOLIO,
          icon: "plus-square",
          hide:
            Number(loggedInUser?.user_data?.user_role) !== 12 &&
            Number(loggedInUser?.user_data?.user_role) !== 14 &&
            loggedInUser?.user_data?.profile_approval
              ? // &&
                // loggedInUser?.user_data?.identity_status !== 0
                false
              : true,
        },
        {
          key: 13,
          name: "Portfolio Listing",
          path: routeName?.MANAGE_PORTFOLIO,
          icon: "tasks",
          hide:
            Number(loggedInUser?.user_data?.user_role) !== 12 &&
            Number(loggedInUser?.user_data?.user_role) !== 14 &&
            loggedInUser?.user_data?.profile_approval
              ? // &&
                // loggedInUser?.user_data?.identity_status !== 0
                false
              : true,
        },
      ],
    },
    {
      key: 14,
      name: "Manage Posts",
      path: "",
      icon: "camera",
      hide:
        Number(loggedInUser?.user_data?.user_role) !== 12 &&
        Number(loggedInUser?.user_data?.user_role) !== 14 &&
        loggedInUser?.user_data?.profile_approval
          ? // &&
            // loggedInUser?.user_data?.identity_status !== 0
            false
          : true,
      subItem: [
        {
          key: 15,
          name: "Posts Listing",
          path: routeName?.MANAGE_POST,
          icon: "list",
          hide:
            Number(loggedInUser?.user_data?.user_role) !== 12 &&
            Number(loggedInUser?.user_data?.user_role) !== 14 &&
            loggedInUser?.user_data?.profile_approval
              ? // &&
                // loggedInUser?.user_data?.identity_status !== 0
                false
              : true,
        },
        {
          key: 16,
          name: "Add Social Post",
          path: routeName?.ADD_POST,
          icon: "plus-square",
          hide:
            Number(loggedInUser?.user_data?.user_role) !== 12 &&
            Number(loggedInUser?.user_data?.user_role) !== 14 &&
            loggedInUser?.user_data?.profile_approval
              ? // &&
                // loggedInUser?.user_data?.identity_status !== 0
                false
              : true,
        },
      ],
    },

    {
      key: 17,
      name: "Manage Jobs",
      path: routeName?.MODEL_JOBS,
      icon: "tasks",
      hide:
        Number(loggedInUser?.user_data?.user_role) == 11 &&
        loggedInUser?.user_data?.profile_approval
          ? // &&
            // loggedInUser?.user_data?.identity_status !== 0
            false
          : true,
    },
    {
      key: 18,
      name: "Manage Projects",
      path: routeName?.CLIENT_PROJECTS,
      icon: "tasks",
      hide:
        (Number(loggedInUser?.user_data?.user_role) == 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14) &&
        loggedInUser?.user_data?.profile_approval
          ? // &&
            // loggedInUser?.user_data?.identity_status !== 0
            false
          : true,
    },
    {
      key: 19,
      name: "Packages",
      path: routeName?.PACKAGES,
      icon: "tags",
      hide:
        Number(loggedInUser?.user_data?.user_role) !== 12 &&
        Number(loggedInUser?.user_data?.user_role) !== 14 &&
        loggedInUser?.user_data?.profile_approval
          ? // &&
            // loggedInUser?.user_data?.identity_status !== 0
            false
          : true,
    },
    {
      key: 20,
      name: "Saved collection",
      path: routeName?.SAVED_COLLECTION,
      icon: "save",
      hide: loggedInUser?.user_data?.profile_approval ? false : true,
    },
    {
      key: 21,
      name: "Social Connections",
      path: routeName?.FOLLOW_DETAILS,
      icon: "save",
      hide: loggedInUser?.user_data?.profile_approval ? false : true,
    },
  ];

  const partnerAccessBased = [
    {
      key: 1,
      name: "Dashboard",
      path: routeName?.DASHBOARD,
      icon: "th-large",
      hide: false,
    },
    {
      key: 2,
      name: "Inbox",
      path: routeName?.CHAT,
      icon: "envelope",
      hide: partnerPermissions?.manage_chat === "on" ? false : true,
    },
    {
      key: 3,
      name: "Manage Projects",
      path: routeName?.CLIENT_PROJECTS,
      icon: "tasks",
      hide: partnerPermissions?.role === "client" ? false : true,
    },
    {
      key: 4,
      name: "Saved collection",
      path: routeName?.SAVED_COLLECTION,
      icon: "save",
      hide: partnerPermissions?.manage_saved_collection === "on" ? false : true,
    },
    {
      key: 5,
      name: "Social Connections",
      path: routeName?.FOLLOW_DETAILS,
      icon: "save",
      hide: partnerPermissions?.manage_follow_details === "on" ? false : true,
    },
    {
      key: 6,
      name: "Manage Jobs",
      path: routeName?.MODEL_JOBS,
      icon: "tasks",
      hide: partnerPermissions?.role !== "client" ? false : true,
    },
    {
      key: 7,
      name: "Manage Portfolio",
      path: "",
      icon: "camera",
      hide:
        partnerPermissions?.manage_portfolio === "on" &&
        partnerPermissions?.role !== "client"
          ? false
          : true,
      subItem: [
        {
          key: 1,
          name: "Add Portfolio",
          path: routeName?.ADD_PORTFOLIO,
          icon: "plus-square",
          hide:
            partnerPermissions?.manage_portfolio === "on" &&
            partnerPermissions?.role !== "client"
              ? false
              : true,
        },
        {
          key: 2,
          name: "Portfolio Listing",
          path: routeName?.MANAGE_PORTFOLIO,
          icon: "tasks",
          hide:
            partnerPermissions?.manage_portfolio === "on" &&
            partnerPermissions?.role !== "client"
              ? false
              : true,
        },
      ],
    },
    {
      key: 8,
      name: "Manage Posts",
      path: "",
      icon: "camera",
      hide:
        partnerPermissions?.manage_posts === "on" &&
        partnerPermissions?.role !== "client"
          ? false
          : true,
      subItem: [
        {
          key: 1,
          name: "Posts Listing",
          path: routeName?.MANAGE_POST,
          icon: "list",
          hide:
            partnerPermissions?.manage_posts === "on" &&
            partnerPermissions?.role !== "client"
              ? false
              : true,
        },
        {
          key: 2,
          name: "Add Social Post",
          path: routeName?.ADD_POST,
          icon: "plus-square",
          hide:
            partnerPermissions?.manage_posts === "on" &&
            partnerPermissions?.role !== "client"
              ? false
              : true,
        },
      ],
    },
    {
      key: 9,
      name: "Post Job",
      path: routeName?.JOB_POST,
      icon: "plus-square",
      hide:
        partnerPermissions?.manage_posts === "on" &&
        partnerPermissions?.role === "client"
          ? false
          : true,
    },
    {
      key: 10,
      name: "Post Calling Calls",
      path: routeName?.CASTING_JOB,
      icon: "plus-square",
      hide:
        partnerPermissions?.manage_posts === "on" &&
        partnerPermissions?.role === "client"
          ? false
          : true,
    },
  ];

  const adminDropItems = [
    {
      key: 1,
      name: "Dashboard",
      path: routeName?.DASHBOARD,
      icon: "th-large",
      hide: false,
    },
    {
      key: 2,
      name: "Inbox",
      path: routeName?.CHAT,
      icon: "envelope",
      hide: accountApproval ? false : true,
    },
    {
      key: 4,
      name: "Settings",
      path: "",
      icon: "gear",
      hide: false,
      subItem: [
        {
          key: 1,
          name: "Edit Profile",
          path: routeName?.EDIT_PROFILE_ADMIN,
          icon: "pencil-square",
          hide: false,
        },
        {
          key: 8,
          name: "Change Password",
          path: routeName?.ACCOUNT_SETTING,
          icon: "gear",
          hide: false,
        },
      ],
    },
    {
      key: 3,
      name: "Users",
      path: "",
      icon: "gear",
      hide: loggedInUser?.user_data?.profile_approval ? false : true,
      subItem: [
        {
          key: 1,
          name: "Talents",
          path: routeName?.MODEL_LIST,
          icon: "pencil-square",
          hide: false,
        },
        {
          key: 2,
          name: "Photographers",
          path: routeName?.PHOTO_LIST,
          icon: "check-square",
          hide: false,
        },
        {
          key: 3,
          name: "Actor",
          path: routeName?.ACTOR_LIST,
          icon: "money",
          hide: false,
        },
        {
          key: 4,
          name: "Client",
          path: routeName?.CLIENT_LIST,
          icon: "calendar",
          hide: false,
        },
        {
          key: 5,
          name: "Partners",
          path: routeName?.PARTNER_LIST,
          icon: "gear",
          hide: false,
        },
      ],
    },
    {
      key: 5,
      name: "Manage Jobs",
      path: routeName?.ALL_ADMIN_JOBS,
      icon: "tasks",
      hide: accountApproval ? false : true,
    },
    {
      key: 6,
      name: "Manage Social Posts",
      path: routeName?.ADMIN_POSTS,
      icon: "camera",
      hide: accountApproval ? false : true,
    },
    {
      key: 7,
      name: "Manage Portfolio",
      path: routeName?.ADMIN_PORTFOLIO,
      icon: "camera",
      hide: accountApproval ? false : true,
    },
    // {
    //   key: 8,
    //   name: "All Transactions",
    //   path: routeName?.ALL_TRANSACTIONS,
    //   icon: "camera",
    //   hide: accountApproval ? false : true,
    // },
  ];

  return (
    <div
      style={{ display: "flex", height: "100vh", overflow: "scroll initial" }}
      className="sideBarLeft"
    >
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader
          prefix={
            <i
              onClick={() => setIsVisible(!isVisible)}
              className={`fa fa-bars fa-large ${
                !isVisible ? "expand_side" : ""
              }`}
            ></i>
          }
        >
          <a
            href=""
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            {partnerPermissions?.name ||
              `${loggedInUser?.user_data?.first_name || ""} ${
                loggedInUser?.user_data?.last_name?.slice(0, 1) || ""
              }`}
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            {Number(loggedInUser?.user_data?.user_role) === 10
              ? adminDropItems.map((item) => (
                  <React.Fragment key={item.key}>
                    {!item.hide && (
                      <NavLink
                        onClick={() => {
                          dispatch(selectedTabAction(null));
                          if (item?.key === 5) {
                            storeData(storageKey.SELECTED_JOB_TYPE, "posted");
                          }
                          if (item?.key === 8) {
                            storeData(
                              storageKey.SELECTED_JOB_TYPE,
                              "proposals"
                            );
                          }
                          if (!item.subItem) {
                            navigate(item.path);
                          } else {
                            toggleSubItems(item.key);
                          }
                        }}
                        exact
                        to={item.path || "#"}
                        activeClassName="activeClicked"
                      >
                        <CDBSidebarMenuItem icon={item.icon}>
                          <span>{item.name}</span>
                          {item.subItem && (
                            <i
                              className={
                                item.key === expandedKey
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                              style={{
                                position: "absolute",
                                right: 5,
                                top: 14,
                              }}
                            ></i>
                          )}
                        </CDBSidebarMenuItem>
                      </NavLink>
                    )}
                    {item.subItem &&
                      item.key === expandedKey &&
                      item.subItem.map((sub) => (
                        <NavLink
                          key={sub.key}
                          onClick={() => {
                            navigate(sub.path);
                          }}
                          exact
                          to={sub.path}
                          activeClassName="activeClicked sub_item_show"
                        >
                          <CDBSidebarMenuItem
                            icon={sub.icon}
                            style={{ marginLeft: 35 }}
                          >
                            {sub.name}
                          </CDBSidebarMenuItem>
                        </NavLink>
                      ))}
                  </React.Fragment>
                ))
              : partnerPermissions?.email
              ? partnerAccessBased?.map((item) => (
                  <React.Fragment key={item.key}>
                    {!item.hide && (
                      <NavLink
                        onClick={() => {
                          dispatch(selectedTabAction(null));
                          if (item?.key === 3) {
                            storeData(storageKey.SELECTED_JOB_TYPE, "posted");
                          }
                          if (item?.key === 6) {
                            storeData(
                              storageKey.SELECTED_JOB_TYPE,
                              "proposals"
                            );
                          }
                          if (!item.subItem) {
                            navigate(item.path);
                          } else {
                            toggleSubItems(item.key);
                          }
                        }}
                        exact
                        to={item.path || "#"}
                        activeClassName="activeClicked"
                      >
                        <CDBSidebarMenuItem icon={item.icon}>
                          <span>{item.name}</span>
                          {item.subItem && (
                            <i
                              className={
                                item.key === expandedKey
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                              style={{
                                position: "absolute",
                                right: 5,
                                top: 14,
                              }}
                            ></i>
                          )}
                        </CDBSidebarMenuItem>
                      </NavLink>
                    )}
                    {item.subItem &&
                      item.key === expandedKey &&
                      item.subItem.map((sub) => (
                        <NavLink
                          key={sub.key}
                          // onClick={() => navigate(sub.path)}
                          onClick={() => {
                            navigate(sub.path);
                          }}
                          exact
                          to={sub.path}
                          activeClassName="activeClicked sub_item_show"
                        >
                          <CDBSidebarMenuItem
                            icon={sub.icon}
                            style={{ marginLeft: 35 }}
                          >
                            {sub.name}
                          </CDBSidebarMenuItem>
                        </NavLink>
                      ))}
                  </React.Fragment>
                ))
              : dropItems.map((item) => (
                  <React.Fragment key={item.key}>
                    {!item.hide && (
                      <NavLink
                        onClick={() => {
                          if (item?.key === 18) {
                            storeData(storageKey.SELECTED_JOB_TYPE, "posted");
                          }
                          if (item?.key === 17) {
                            storeData(
                              storageKey.SELECTED_JOB_TYPE,
                              "proposals"
                            );
                          }
                          if (!item.subItem) {
                            navigate(item.path);
                          } else {
                            toggleSubItems(item.key);
                          }
                        }}
                        exact
                        to={item.path || "#"}
                        activeClassName="activeClicked"
                      >
                        <CDBSidebarMenuItem icon={item.icon}>
                          <span>{item.name}</span>
                          {item.subItem && (
                            <i
                              className={
                                item.key === expandedKey
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                              style={{
                                position: "absolute",
                                right: 5,
                                top: 14,
                              }}
                            ></i>
                          )}
                        </CDBSidebarMenuItem>
                      </NavLink>
                    )}
                    {item.subItem &&
                      item.key === expandedKey &&
                      item.subItem.map((sub) => {
                        return (
                          <>
                            {!sub?.hide ? (
                              <NavLink
                                key={sub.key}
                                onClick={() => {
                                  navigate(sub.path);
                                }}
                                exact
                                to={sub.path}
                                activeClassName="activeClicked sub_item_show"
                              >
                                <CDBSidebarMenuItem
                                  icon={sub.icon}
                                  style={{ marginLeft: 35 }}
                                >
                                  {sub.name}
                                </CDBSidebarMenuItem>
                              </NavLink>
                            ) : undefined}
                          </>
                        );
                      })}
                  </React.Fragment>
                ))}

            {partnerPermissions?.email ? (
              <NavLink
                onClick={() => setIsAccess(true)}
                exact
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fa fa-sign-out">
                  Exit Access
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <NavLink
                onClick={() => setShow(true)}
                exact
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="fa fa-sign-out">
                  Log Out
                </CDBSidebarMenuItem>
              </NavLink>
            )}
             <div className="downloadIcons">
                    <a href="https://apps.apple.com/in/app/book-sculp/id6444509575">
                      <img src="https://booksculp.com/LIVE/uploads/appstore.png" alt="Book Sculp | Modeling Agency | Modeling Jobs | Modeling agents near me"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.sculp_agency">
                      <img src="https://booksculp.com/LIVE/uploads/playstore.webp" alt="Book Sculp | Modeling Agency | Modeling Jobs | Modeling agents near me" />
                    </a>
                  </div>
          </CDBSidebarMenu>
        </CDBSidebarContent>
       
      </CDBSidebar>
      
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You want to Log out for now. You can login to this account again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Not Now
          </Button>
          <Button variant="primary" onClick={() => logout()}>
            Yes, Log Out
          </Button>
        </Modal.Footer>
      </Modal>
      {isAccess ? (
        <DeletePost
          setShow={setIsAccess}
          show={isAccess}
          title="Alert"
          handleConfirm={() => {
            removeData(storageKey.ACCESS_BASED);
            removeData(storageKey.ACCESS_TOKEN);
            dispatch(partnerAccessPermissions(null));
            const queryParams = new URLSearchParams({
              type:
                permissions?.role === "model" ? "talent" : permissions?.role,
              tab: 1,
            });
            navigate(`${routeName.LIST}?${queryParams.toString()}`, {
              replace: true,
            });
          }}
          text="Are you sure you want to exit  Access?"
        />
      ) : undefined}
    </div>
  );
};

export default Sidebar;
